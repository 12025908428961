<template>
	<div>
		<CCard>
			<CCardBody>
				<CRow>
					<CCol>
						<div class="user-info">
							<CRow class="form-group">
								<CCol class="user-field col-md-3 col-lg-2 col-12">
									{{ $t("Profile.Email") }}
								</CCol>
								<CCol class="user-value text-muted pt-2 pt-md-0">
									{{ currentUser.email }}
								</CCol>
							</CRow>
							<CRow class="form-group">
								<CCol class="user-field col-md-3 col-lg-2 col-12">
									{{ $t("Profile.Name") }}
								</CCol>
								<CCol class="user-value text-muted pt-2 pt-md-0">
									{{ currentUser.fullName }}
								</CCol>
							</CRow>
							<CRow class="form-group">
								<CCol class="user-field col-md-3 col-lg-2 col-12">
									{{ $t("Profile.Role") }}
								</CCol>
								<CCol class="user-value text-muted pt-2 pt-md-0">
									{{ currentUser.roleText }}
								</CCol>
							</CRow>
							<CRow class="form-group">
								<CCol class="user-field col-md-3 col-lg-2 col-12">
									{{ $t("Profile.PhoneNumber") }}
								</CCol>
								<CCol class="user-value text-muted pt-2 pt-md-0">
									{{ currentUser.phone }}
								</CCol>
							</CRow>
						</div>
						<div class="user-edit">
							<CButton color="outline-custom" @click="showUpdateModal(true)">
								{{ $t("Profile.Edit") }}
							</CButton>
						</div>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
		<UpdateProfile v-if="isShowUpdate" :current-user="currentUser" @hide="showUpdateModal" />
	</div>
</template>

<script>
import { GET_USER_LOGIN_PROFILE } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState } = createNamespacedHelpers("authentication")

export default {
	name: "UsersProfile",
	components: {
		UpdateProfile: () => import("../components/UpdateProfile"),
	},
	data() {
		return {
			isShowUpdate: false,
		}
	},
	computed: {
		...mapState(["currentUser"]),
	},
	created() {
		this.getUserProfile()
	},
	methods: {
		...mapActions({ GET_USER_LOGIN_PROFILE }),
		getUserProfile: async function () {
			await this.GET_USER_LOGIN_PROFILE()
		},
		showUpdateModal(status = false) {
			this.isShowUpdate = status
			this.$store.commit("set", ["modalShow", status])
		},
	},
}
</script>
